.header {
    height: 100px;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    border-bottom: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }

  .box{
    padding-left: 5px;
    padding-right: 15px;
    @media (max-width: 0px) {
     padding-left: 0px;
     padding-right: 20px;
    }
  }
  
  .link {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
  
    @media (max-width: 0px) {
      height: 42px;
      width: 100%;
    }
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  }
  
  .subLink {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    }
  }
  
  .dropdownFooter {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    margin: calc(var(--mantine-spacing-md) * -1);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: 1px solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }

  .wrapperBtn{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .connectBtn{
    width: 50%;
  }
.navbar {
    width:  100%;
    height:  750px;
    padding: var(--mantine-spacing-md);
    display: flex;
    flex-direction: column;
    border-right:  1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
    margin-top: 30px;
  }
  
  .navbarMain {
    flex: 1;
    margin-top:  50px;
  }
  
  .link {
    width:  50px;
    height:  50px;
    border-radius: var(--mantine-radius-md);
    display: flex;
    align-items: center;
    justify-content: center;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  
    &:hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
    }
  
    &[data-active] {
      &,
      &:hover {
        background-color: #e0e0e0;
        color: black;
      }
    }
  }
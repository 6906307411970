.root {
    background-color: #11284b;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: middle; 
    background-image: linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #000000 70%),
      url('../assets/about.jpg');
    padding-top: calc(var(--mantine-spacing-xl) * 3);
    padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
  }

  .rootMobile {
    background-color: #11284b;
    background-size: cover;
    overflow: hidden;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: middle; 
    background-image: linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #000000 70%),
      url('../assets/about.jpg');
    padding-top: calc(var(--mantine-spacing-xl) * 3);
    padding-bottom: calc(var(--mantine-spacing-xl) * 3);
      width: 100%;
      height: auto;
  }
  
  .inner {
    display: flex;
    justify-content: space-between; 
    width: 100vw;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .innerMobile {
    display: flex;
    justify-content: space-between; 
    width: 100vw;
      flex-direction: column;
  }
  
  .image {
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  .content {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    margin-right: calc(var(--mantine-spacing-xl) * 3);
    /* background-color: #0000005e; */
    padding: 30px;
    border-radius: 10px;
    width: 70%;
    @media (max-width: 768px) {
      margin-right: 0px;
      width: 80%;
    }
  }

  .contentMobile {
    padding-top: calc(var(--mantine-spacing-xl) * 2);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
    /* background-color: #0000005e; */
    padding: 30px;
    border-radius: 10px;

      margin-right: 0px;
      width: 80%;
    }
    
  .title {
    color: var(--mantine-color-white);
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-weight: 800;
    line-height: 1.05;
    max-width: 500px;
    font-size: 34px;
    letter-spacing: 4px;
  
    @media (max-width: 768px) {
      max-width: 100%;
      font-size: 34px;
      line-height: 1.15;
    }
  }

  .titleMobile {
    color: var(--mantine-color-white);
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-weight: 800;
    line-height: 1.05;
    letter-spacing: 4px;
      max-width: 100%;
      font-size: 34px;
      line-height: 1.15;
    }
  
  
  .description {
    color: var(--mantine-color-white);
    opacity: 0.9;
    max-width: 550px;
  
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .descriptionMobile {
    color: var(--mantine-color-white);
    opacity: 0.9;
  
      max-width: 100%;
    }
  
  .control {
    background-color: #ba0c2f;
  }
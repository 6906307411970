.colors{
    color: rgba(159, 159, 159, 0.389);
}

.home-container {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.901),
        rgba(255, 255, 255, 0.248)
      );
    background-size: cover;
    background-position: center;
    border-top: 2px solid black;
    z-index: 15;
    top: -10px;
}

#button {
    cursor: pointer;
    width: 190px;
    height: 40px;
    line-height: 40px;
    background-color:rgb(192,192,192);
    border: 2px solid rgb(192,192,192);
    float: left;
    transition: all 0.5s;
    color: black;
  }
  
  #button:hover {
    box-shadow: 0 0 8px 4px rgba(207, 206, 206, 0.25) inset, 0 0 8px 4px rgba(207, 206, 206, 0.25);
    border: 2px solid rgb(192,192,192);
    background-color: rgb(192,192,192);
    /* color: rgba(255,255,255,1); */
  }

  
.card-container {
  height: 90%;
  width: 25%;
  min-width: 280px;
  max-height: 580px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border-radius: 10px;
  border: 2px solid black
}

.card-img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 40%;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: center;
}

.card-img img {
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  object-fit: cover;

}

.card-text {
  width: 100%;
  height: 65%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 0px 0px 10px 10px;
  justify-content: center;
  text-align: center;
}

.card-text h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-text h1 {
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 35px;
  color: #e5aa3b;
}
  

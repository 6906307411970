.title {
  font-size: 24px;
  font-weight: 900;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 24px;
  }
  &::after {
    content: '';
    display: block;
    background-color: #ba0c2f;
    width: 45px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
  }
}


.description {
  margin: auto;
  color: white;
  &::after {
    content: '';
    display: block;
    background-color: rgb(255, 255, 255);
    width: 45px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
    margin-left: auto;
    margin-right: auto;
  }
}

.card {
  align-self: center;
  border: 1px solid #ba0c2f3a;
  background-color: black !important;
}

.input{
  background-color: #fff !important;
  margin: 10px 0px;
}

.cardTitle {
  &::after {
    content: '';
    display: block;
    background-color: #ba0c2f;
    width: 45px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
  }
}

.filterCars {
  display: none !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}
.card {
  background-image: linear-gradient(
    -150deg,
    var(--mantine-color-dark-4) 0%,
    #000000  100%
  );
    min-width: 150px !important;
    min-height: 410px;
  }
  
  .imageSection {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #4d4f6683;
  }
  
  .label {
    margin-bottom: var(--mantine-spacing-xs);
    line-height: 1;
    font-weight: 700;
    font-size: var(--mantine-font-size-xs);
    letter-spacing: rem(-0.25px);
    text-transform: uppercase;
  }
  
  .section {
    padding: var(--mantine-spacing-md);
    border-bottom: 1px solid #4d4f6683;
  }
  
  .icon {
    margin-right: rem(5px);
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-2));
  }
.wrapper {
    position: relative;
  }
  
  .dropzone {
    border-width: 1px;
  }
  
  .icon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }
  
  .control {
    position: absolute;
    width: 250px;
    left: calc(50% - 125px);
    bottom: -20px;
  }
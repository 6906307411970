.carHeroWrapper{
  max-width: 90%;
  justify-content: center;
  align-items: center;
  border-radius: var(--mantine-radius-md);
  padding: 20px !important;
  @media (max-width: 60rem) {
    max-width: 100%;
  }
}

.carHeroWrapperMobile {

  justify-content: center;
  align-items: center;
  border-radius: var(--mantine-radius-md);
  padding: 20px !important;

    max-width: 100%;
}

.topWrapper{
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;

    background-image: linear-gradient(
      -90deg,
      var(--mantine-color-dark-4) 0%,
      #807e7e  100%
    );

    @media (max-width: 60rem) {
        flex-direction: column;
        max-width: 100%;
      }
}

.topWrapperMobile {
  justify-content: center;
  border-radius: 10px;

  background-image: linear-gradient(
    -90deg,
    var(--mantine-color-dark-4) 0%,
    #807e7e  100%
  );

      flex-direction: column;
      max-width: 100%;
}

.bottomWrapper{
  flex-direction: row;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  max-width: 80%;
  @media (max-width: 60rem) {
    max-width: 100%;
    width: 100% !important;
  }

}

.bottomWrapperMobile {
  flex-direction: row;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
    max-width: 100%;
    width: 100% !important;
}

.topLeftWrapper{
     width: 50%;
     padding: 20px !important;

     @media (max-width: 60rem) {
        width: 100%;
        padding: 0px !important;
      }
}

.topLeftWrapperMobile {
     width: 100%;
     padding: 0px !important;
}

.topRightWrapper{
    width: 50%;
    max-width: 500px;
    border-radius: 10px;
    margin-top: 20px;
    @media (max-width: 60rem) {
       width: 100%;
       margin-top: 0px;
     }
}

.topRightWrapperMobile {

  max-width: 500px;
  border-radius: 10px;


     width: 100%;
     margin-top: 0px;
   
}

.title{
  &::after {
    content: '';
    display: block;
    background-color: #ba0c2f;
    width: 45px;
    height: 2px;
    margin-top: var(--mantine-spacing-sm);
  }
}

.icon{
  color:#fff;
}

.carWrapper{
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}
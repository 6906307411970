.footer {
  padding-top: calc(var(--mantine-spacing-xl) * 1);
  padding-bottom: calc(var(--mantine-spacing-xl) * 1);
  background-color: #e0dfdd;
  border-top: 1px solid #2c2b29 !important;
}

.logo {
  max-width: 300px;
  align-items: center;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.logoMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    margin-top: 5px;
    color:#2c2b29 !important;
    text-align: center;
    @media (max-width: 480px) {
      margin-top: var(--mantine-spacing-xs);
      text-align: center;
    }
  }

.descriptionMobile {
  color:#2c2b29 !important;
    margin-top: var(--mantine-spacing-xs);
    text-align: center;
}

.inner {
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
}

.innerMobile {
  display: flex;
  justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.groups {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    display: none;
  }
}

.groupsMobile {
    display: none;
}

.wrapper {
  width: 160px;
}

.link {
  display: block;
  color:#2c2b29 !important;
  font-size: var(--mantine-font-size-sm);
  padding-top: 3px;
  padding-bottom: 3px;

  &:hover {
    text-decoration: underline;
  }
}

.title {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
  color: #2c2b29 !important;
}

.afterFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: 1px solid #2c2b29 !important;

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.afterFooterMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mantine-spacing-xl);
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: 1px solid #2c2b29 !important;
    flex-direction: column;

}

.social {
  @media (max-width: 768px) {
    margin-top: var(--mantine-spacing-xs);
  }
}

.socialMobile {
    margin-top: var(--mantine-spacing-xs);
}